import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class CardAuthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
