import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  // NbAuthComponent,
  NbLoginComponent,
  // NbRegisterComponent,
  // NbLogoutComponent,
  // NbRequestPasswordComponent,
  // NbResetPasswordComponent,
} from '@nebular/auth';
import {AuthGuard} from './auth-guard.service';
import {CardLogoutComponent} from './auth/logout/logout.component';
import {CardLoginComponent} from './auth/login/login.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.CardHomeModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
    loadChildren: () => import('./profile/profile.module').then(m => m.CardProfileModule),
  },
  {
    path: 'edit',
    canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
    loadChildren: () => import('./manage/manage.module').then(m => m.CardManageModule),
  },
  {
    path: 'gdprconfirmresult/:result',
    loadChildren: () => import('./gdprconfirmresult/gdprconfirmresult.module').then(m => m.CardGdprConfirmResultModule),
  },
  // {
  //   path: 'pages',
  //   canActivate: [AuthGuard], // here we tell Angular to check the access with our AuthGuard
  //   loadChildren: 'app/pages/pages.module#PagesModule'
  // },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        // component: NbLoginComponent
        component: CardLoginComponent,
      },
      {
        path: 'logout',
        component: CardLogoutComponent
        // component: CardLoginComponent,
      }
    ]
    // loadChildren: () => import('./auth/auth-routing.module').then(m => m.CardAuthRoutingModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
