import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {
  NbLayoutModule,
  NbMenuModule,
  NbTabsetModule,
  NbSidebarModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbButtonModule,
  NbSelectModule,
  NbInputModule,
  NbContextMenuModule
} from '@nebular/theme';

import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  CardHeaderComponent,
  // NgdHeroComponent,
  // NgdIconCardComponent,
  // NgdTextCardComponent,
  CardFooterComponent,
  // NgdFragmentTargetDirective,
  // NgdPageTocComponent,
  // NgdPageTabsComponent,
  // NgdColorSwatchDirective,
  // NgdDescriptionDirective,
  // NgdSearchComponent,
  // NgdEvaComponent,
} from './components';

// import {
//   NgdHighlightService,
//   NgdTextService,
//   NgdTabbedService,
//   NgdStructureService,
//   NgdCodeLoaderService,
//   NgdIframeCommunicatorService,
//   NgdStylesService,
//   NgdVersionService,
//   NgdVisibilityService,
//   NgdPaginationService,
//   NgdAnalytics,
//   NgdMenuService,
//   NgdMetadataService,
// } from './services';

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbSidebarModule,
    NbCardModule,
    NbMenuModule,
    NbContextMenuModule,
    NbTabsetModule,
    NbIconModule,
    NbButtonModule,
    NbSelectModule,
    NbInputModule,
    NbEvaIconsModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    CardHeaderComponent,
    // NgdHeroComponent,
    // NgdIconCardComponent,
    // NgdTextCardComponent,
    CardFooterComponent,
    // NgdFragmentTargetDirective,
    // NgdPageTocComponent,
    // NgdPageTabsComponent,
    // NgdColorSwatchDirective,
    // NgdDescriptionDirective,
    // NgdSearchComponent,
    // NgdEvaComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NbIconModule,
    NbLayoutModule,
    NbSidebarModule,
    NbCardModule,
    NbMenuModule,
    NbTabsetModule,
    NbCheckboxModule,
    CardFooterComponent,
    CardHeaderComponent,
    // NgdHeaderComponent,
    // NgdHeroComponent,
    // NgdIconCardComponent,
    // NgdTextCardComponent,
    // NgdFooterComponent,
    // NgdFragmentTargetDirective,
    // NgdPageTocComponent,
    // NgdPageTabsComponent,
    // NgdColorSwatchDirective,
    // NgdDescriptionDirective,
    // NgdEvaComponent,
  ],
})
export class CardThemeModule {
  static forRoot(): ModuleWithProviders<CardThemeModule> {
    return {
      ngModule: CardThemeModule,
      providers: [
        // NgdHighlightService,
        // NgdTextService,
        // NgdTabbedService,
        // NgdStructureService,
        // NgdCodeLoaderService,
        // NgdIframeCommunicatorService,
        // NgdStylesService,
        // NgdVersionService,
        // NgdPaginationService,
        // NgdAnalytics,
        // NgdMenuService,
        // NgdVisibilityService,
        // NgdMetadataService,
      ],
    };
  }
}
