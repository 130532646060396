import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NbAuthService} from '@nebular/auth';


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  token: any;
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(NbAuthService);
    authService.getToken().subscribe(token => {
      this.token = token;
    });
    const tokenizedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`
      }
    });
    return next.handle(tokenizedReq);
  }
}
