import { Component } from '@angular/core';
import {NbAuthResult, NbLoginComponent} from '@nebular/auth';

@Component({
  selector: 'app-card-login',
  templateUrl: './login.component.html',
})

export class CardLoginComponent extends NbLoginComponent {

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    });
  }
}

