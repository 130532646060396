<div class="content-center">
  <div class="section left">
    <button class="sidebar-toggle" (click)="toggleSidebar()">
      <nb-icon icon="menu-2"></nb-icon>
    </button>
    <a routerLink="/"><img alt="CTU logo" class="logo-resp" src="assets/logo_CVUT.svg"></a>
    <img alt="ESC logo" class="logo-resp" src="assets/ESC_logo_ctu.png">
  </div>
  <div class="section middle">
  </div>
  <div class="section right">
      <ng-template [ngIf]="!shibbResp.cn">
        <a nbButton size="large" status="primary" style="display: block; margin: 0" href="/Shibboleth.sso/Login?target=https://esc.cvut.cz/">
          <nb-icon style="display: block; margin: auto;" icon="person-outline"></nb-icon>
          <span style="display: block; font-size: 60%;">{{ 'přihlásit' | translate }}</span>
        </a>
      </ng-template>
      <ng-template [ngIf]="shibbResp.cn">
        <a nbButton size="large" status="primary" style="display: block; margin: 0" [nbContextMenu]="menuItems">
          <nb-icon style="display: block; margin: auto;" icon="person-outline"></nb-icon>
          <span style="display: block; font-size: 60%;">{{ shibbResp.cn }}</span>
        </a>
      </ng-template>
    <a nbButton size="large" status="primary" style="display: block; margin: 0" (click)="changeLang()">
      <nb-icon style="display: block; margin: auto;" icon="globe-2"></nb-icon>
      <span style="display: block; font-size: 60%;">{{ langText }}</span>
    </a>
  </div>
</div>

