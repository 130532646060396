import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ShibbolethService} from './auth/shibboleth.service';
import { tap } from 'rxjs/operators';
import {NbIconConfig, NbToastrService} from '@nebular/theme';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private shibbService: ShibbolethService, private toastrService: NbToastrService, private router: Router) {
  }

  toastrMessage: string;
  toastrTitle: string;

  canActivate() {
    let activate: boolean;
    this.shibbService.fetchShibb().subscribe(() => {
      console.log('auth checked');
    });
    this.shibbService.shibbStatus.subscribe(shibb => {
      if (shibb.cn && shibb.affiliation.includes('student')) {
        activate = true;
      } else {
        // this.router.navigateByUrl('/Shibboleth.sso/Login?target=https://esc.cvut.cz/');
        activate = false;
        const iconConfig: NbIconConfig = { icon: 'alert-triangle-outline', pack: 'eva' }
        if (localStorage.getItem('lang') === 'cs' || localStorage.getItem('lang') === null) {
          this.toastrMessage = 'Není co nastavovat, v systému nebylo nalezeno aktivní studium.';
          this.toastrTitle = 'Nejsi student.';
        } else {
          this.toastrMessage = 'There is nothing to be set up, no active study was found in the system.';
          this.toastrTitle = 'You\'re not a student.';
        }
        const toastRef = this.toastrService.show(
          this.toastrMessage,
          this.toastrTitle,
          {
            limit: 1,
            icon: iconConfig,
            destroyByClick: true,
            duration: 0,
            status: 'warning'
          }
        );
      }
    });
    return activate;
  }
}
