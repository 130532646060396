import {Component, Input, OnInit} from '@angular/core';
import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Subscription} from 'rxjs';
import {TokenDetail} from './auth/token.service';
import {ShibbolethService} from './auth/shibboleth.service';

@Component({
  selector: 'app-card-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class CardAppComponent implements OnInit {
  title = 'CTU CARD';

  private window: Window;
  tokenSub: Subscription;
  token: TokenDetail;
  collapsedBreakpoints = ['xs', 'is', 'sm', 'md', 'lg'];
  menuItems = [
    { title: 'Přihlásit se', url: '/Shibboleth.sso/Login?target=https://esc.cvut.cz/' },
    { title: 'CZ / EN', url: '/en/' },
    { title: 'neco', link: '/nevim/' }
    ];

  @Input() sidebarTag: string;

  constructor(
    private menuService: NbMenuService,
    private shibbService: ShibbolethService,
    private sidebarService: NbSidebarService
  ) {}

  ngOnInit() {
    this.menuItems = [
      { title: 'Přihlásit se', url: '/Shibboleth.sso/Login?target=https://esc.cvut.cz/' },
      { title: 'CZ / EN', link: '' }
    ];
    this.window = window;
    this.shibbService.shibbStatus.subscribe(shibb => {
      if (shibb.cn) {
          this.menuItems = [
            { title: 'Uživatelský profil', link: '/edit' },
            {
              title: 'Odhlásit',
              url: '/Shibboleth.sso/Logout?return=https://idp2.civ.cvut.cz/idp/logout.jsp&?target=https://esc.cvut.cz/'
            },
            { title: 'CZ / EN', link: ''}
            ];
        }
      });
    this.menuService.onItemClick().subscribe((e) => {
      if (e.item.title === 'CZ / EN') {
        if (localStorage.getItem('lang') === 'cs' || localStorage.getItem('lang') === null) {
          localStorage.setItem('lang', 'en');
          window.location.reload();
        } else {
          localStorage.setItem('lang', 'cs');
          window.location.reload();
        }
      }
      this.sidebarService.collapse(this.sidebarTag);
    });
  }
}
