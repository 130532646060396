import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NB_WINDOW, NbLayoutModule, NbMenuItem, NbMenuService, NbSidebarService} from '@nebular/theme';
import {NbAuthService, NbAuthJWTToken} from '@nebular/auth';
import {TokenDetail} from '../../../auth/token.service';
import {Subscription} from 'rxjs';
import {ShibbolethService} from '../../../auth/shibboleth.service';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-card-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHeaderComponent implements OnInit, OnDestroy, NbLayoutModule {

  // @Input() showSearch = true;
  // @HostBinding('class.docs-page') @Input() isDocs = false;
  langText: string;

  private window: Window;
  tokenSub: Subscription;
  token: TokenDetail;
  shibbResp: any;
  menuItems = [
    { title: 'Uživatelský profil', link: '/edit' },
    { title: 'Odhlásit', url: '/Shibboleth.sso/Logout?return=https://idp2.civ.cvut.cz/idp/logout.jsp&?target=https://esc.cvut.cz/' }
    ];

  @Input() sidebarTag: string;

  constructor(
    @Inject(NB_WINDOW) window,
    private sidebarService: NbSidebarService,
    private authService: NbAuthService,
    private shibbService: ShibbolethService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.window = window;
    this.tokenSub = this.shibbService.shibbStatus
      .subscribe((shibb: any) => {
        this.shibbResp = shibb;
      });
    this.shibbService.fetchShibb().subscribe(() => {
      this.cd.detectChanges();
    });
    if (localStorage.getItem('lang') === 'cs' || localStorage.getItem('lang') === null) {
      this.langText = 'EN';
      this.menuItems = [
        { title: 'Uživatelský profil', link: '/edit' },
        { title: 'Odhlásit', url: '/Shibboleth.sso/Logout?return=https://idp2.civ.cvut.cz/idp/logout.jsp&?target=https://esc.cvut.cz/' }
      ];
    } else {
      this.langText = 'CZ';
      this.menuItems = [
        { title: 'User profile', link: '/edit' },
        { title: 'Logout', url: '/Shibboleth.sso/Logout?return=https://idp2.civ.cvut.cz/idp/logout.jsp&?target=https://esc.cvut.cz/' }
      ];
    }
  }

  toggleSidebar() {
    this.sidebarService.toggle(false, this.sidebarTag);
  }

  ngOnDestroy(): void {
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }
  }

  changeLang() {
    if (localStorage.getItem('lang') === 'cs' || localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'en');
      window.location.reload();
    } else {
      localStorage.setItem('lang', 'cs');
      window.location.reload();
    }
  }
}
