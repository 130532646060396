import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NbComponentStatus} from '@nebular/theme/components/component-status';



@Injectable({
  providedIn: 'root'
})
export class ShibbolethService {
  private shibbResp = new BehaviorSubject<any>([]) ;
  private gdprResp = new BehaviorSubject<any>([]) ;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})
  };
  httpOptionsHtml = {
    headers: new HttpHeaders({
      Accept: 'text/html, application/xhtml+xml, */*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    responseType: 'text/html' as 'json'
  };

  get shibbStatus() {
    return this.shibbResp.asObservable();
  }

  get gdprStatus() {
    return this.gdprResp.asObservable();
  }

  constructor(private http: HttpClient) {}

  fetchShibb() {
    return this.http.get<any>('/authshib')
      .pipe(tap(shibb => {
        this.shibbResp.next(shibb);
      }));
  }

  fetchGDPR() {
    return this.http.get<any>('/manage', this.httpOptionsHtml)
      .pipe(tap(gdpr => {
        this.gdprResp.next(gdpr);
      }),
        catchError(err => {
        this.gdprResp.next(err);
        return Observable.throw(err.statusText);
      }));
  }

  changeGDPR(toggle) {
    let yes: string;
    if (toggle) {
      yes = '1';
    } else {
      yes = '0';
    }
    return this.http.get<any>('/manage/update?gdpr=' + yes, this.httpOptionsHtml)
      .pipe(tap(gdpr => {
        this.gdprResp.next(gdpr);
      }));
  }
}
