// TODO: in proxy.conf.json allow secure only
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { CardAppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NbThemeModule, NbLayoutModule, NbSidebarModule, NbMenuModule, NbListModule, NbToastrModule, NbDialogModule} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { CardThemeModule } from './@theme/theme.module';
import {NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken} from '@nebular/auth';
import { AuthGuard } from './auth-guard.service';
import { CardAuthComponent } from './auth/auth.component';
import {TokenInterceptorService} from './@theme/services/token-interceptor.service';

import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {CardLogoutComponent} from './auth/logout/logout.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
registerLocaleData(localeCs, 'cs-CZ');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// import { structure  } from '../structure';
// import { DOCS, STRUCTURE } from './app.options';
// const docs = require('../output.json');

@NgModule({
  declarations: [
    CardAppComponent,
    CardAuthComponent,
    CardLogoutComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NbThemeModule.forRoot({ name: 'card-ctu' }),
    CardThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          baseEndpoint: 'https://api.card1.dev.cvut.cz/v1/auth',
          login: {
            endpoint: '/login',
            method: 'post',
            redirect: {
              success: '/profile', // profile page path
              failure: null, // stay on the same page
            }
          },
          logout: {
            endpoint: 'https://api.card1.dev.cvut.cz/v1/logout',
            method: 'post',
            requireValidToken: true,
            redirect: {
              success: '/', // welcome page path
              failure: null, // stay on the same page
            }
          },
          token: {
            class: NbAuthJWTToken,
            key: 'access_token'
          },
        }),
      ],
      forms: {},
    }),
  ],
  providers: [
    Title,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    HttpClient
    // { provide: STRUCTURE, useValue: structure },
    // { provide: DOCS, useValue: docs },
  ],
  bootstrap: [CardAppComponent]
})
export class AppModule { }
