<nb-layout>
  <nb-layout-header>
    <app-card-header></app-card-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" [tag]="sidebarTag" [responsive]="true" [compactedBreakpoints]="[]"
              [collapsedBreakpoints]="collapsedBreakpoints">
    <nb-menu [items]="menuItems" tag="leftMenu"></nb-menu>
  </nb-sidebar>
  <nb-layout-column>
    <div>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </nb-layout-column>
  <nb-layout-footer>
    <app-card-footer></app-card-footer>
  </nb-layout-footer>

</nb-layout>
